<template>
    <div>
        <Alert v-if="errorMessage" variant="danger" class="mb-4">
            <AlertDescription>
                <p v-html="errorMessage" />
            </AlertDescription>
        </Alert>

        <div class="login-form">
            <form @submit.prevent="onFormSubmit">
                <fieldset class="mb-4">
                    <label for="sign-in-user-name-input" class="block mb-1 font-bold">
                        {{ $t("SignIn.UserNameLabelText") }}
                    </label>
                    <input id="sign-in-user-name-input" v-model="username" type="text" class="py-1 rounded-md w-full" />
                    <span class="text-danger">{{ usernameError }}</span>
                </fieldset>

                <fieldset class="mb-4">
                    <label for="sign-in-password-input" class="block mb-1 font-bold">
                        {{ $t("Global.PasswordLabel") }}
                    </label>
                    <input
                        id="sign-in-password-input"
                        v-model="password"
                        type="password"
                        class="py-1 rounded-md w-full" />
                    <span class="text-danger">{{ passwordError }}</span>
                    <p class="mt-2">
                        <a href="/forgot-password">{{ $t("SignIn.ForgotPasswordText") }}</a>
                    </p>
                </fieldset>

                <Button id="sign-in-button" type="submit" :disabled="loading" variant="primary">
                    {{ signInButtonText }}
                </Button>
            </form>

            <p class="mt-1">
                {{ $t("SignIn.CreateAccount") }} <a href="/register">{{ $t("SignIn.SignupLink") }}</a>
            </p>
        </div>
    </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
const guestCookie = useGuestCookie();
const { logIn } = useIdentity();
const initializeState = useInitializeState();
const { fetchBOM, saveBOM } = useBOM();

const username = ref("");
const password = ref("");
const usernameError = ref("");
const passwordError = ref("");
const errorMessage = ref("");
const loading = ref(false);

const emit = defineEmits<{ success: [response: LogInResponse] }>();

const signInButtonText = computed(() => t(loading.value ? "SignIn.LoginButtonLoading" : "SignIn.LoginButtonText"));

// TODO: replace this with valibot schema
function validate() {
    usernameError.value = "";
    passwordError.value = "";

    if (!username.value) {
        usernameError.value = t("SignIn.UserNameRequiredErrorMessage");
    }
    if (!password.value) {
        passwordError.value = t("Global.PasswordRequired");
    }

    return !(usernameError.value || passwordError.value);
}

async function saveAnonBOM(bomSearch?: BOMSearch) {
    // if they had an anonymous bom, save it to their account on login.
    if (!bomSearch) return;

    try {
        bomSearch.HashId = ""; // no longer "local", this will create a new record
        await saveBOM(bomSearch);

        // remove the anon bom from local storage since we just saved it to their account.
        localStorage.removeItem("ecia-bom-anonymous");
    } catch (err) {
        // don't let this failure stop the log in, we'll just try again next time they log in
        console.error(err);
    }
}

async function onFormSubmit() {
    if (!validate()) return;

    loading.value = true;
    try {
        const anonBOM = await fetchBOM("local");
        const logInResponse = await logIn({ UserName: username.value, Password: password.value });

        // emit success event first because changing auth state might destroy BomPartAddAnon
        emit("success", logInResponse);
        await initializeState();
        await saveAnonBOM(anonBOM);

        guestCookie.value = null; // delete cookie
    } catch (err) {
        const error = err as Error;
        errorMessage.value = error.message || t("Global.GenericError");
    } finally {
        loading.value = false;
    }
}
</script>
